import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Link, makeStyles, Snackbar, Typography, useTheme } from "@material-ui/core";
import StatesContext from "../contexts/statesContext";
import NoticesContext from "../contexts/noticesContext";
import PaymentsContext from "../contexts/paymentsContext";
import NotificationContext from "../contexts/notificationsContext";
import MuiAlert from '@material-ui/lab/Alert';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import moment from "moment";
import ConformityProvidedContext from "../contexts/conformityProvidedContext";
import BasicModal from "./UI/BasicModal";
import DocumentsNoticesContext from '../contexts/documentsNoticesContext';
import NoticesViewExt from "./noticesViewExt";
import ContactContext from "../contexts/contactContext";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  textLine: {
    marginTop: "1em",
  },
  scroller: {
    height: "200px",
    overflowY: "auto",
  },
  modalTitle: {
    textAlign: 'center', 
    fontSize: '1.5rem',  
    marginBottom: '1.5em',  
  },
  modalBody: {
    marginBottom: '1em',  
    textAlign: 'left', 
  },
  modalAttachment: {
    marginTop: '1em', 
    textAlign: 'left',  
  },
    modalButton: {
    marginTop: '1em', 
  },
});


export default function UserDataComponent(props) {
  const user = props;
  const [allStates, setAllStates] = useState([]);
  const [allNotices, setAllNotices] = useState([]);
  const [newPayments, setNewPayments] = useState([]);
  const [newness, setNewness] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasContact, setHasContact] = useState(false);
  const [currentNotice, setCurrentNotice] = useState(null); 
  const [openModal, setOpenModal] = useState(false);  
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [noticesRequiringConfirmation, setNoticesRequiringConfirmation] = useState([]);
  const [noticeDocument, setNoticeDocument] = useState(null);
  const [fileState, setFileState] = useState(null);

  const classes = useStyles();
  const { id_proveedor } = user?.props;
  const theme = useTheme();
  const cuit = String(JSON.parse(window.localStorage.getItem("provider")).cuit).trim();

  const fetchData = useCallback(async () => {
    const [notifications, notices, states, payments, contact, allConformities] = await Promise.all([
      NotificationContext.getNewness(),
      NoticesContext.fetchNoticesByCuit(),
      StatesContext.fetchStates(),
      PaymentsContext.fetchPayments(),
      ContactContext.alertNewContactProv(),
      ConformityProvidedContext.getAllConformityProvided(), 
    ]);
  
    setAllStates(states);
    setHasContact(contact?.status);
  
    const notificationData = notifications?.filter(
      (notif) => notif.id_proveedor === id_proveedor
    );
    if (notificationData) setNewness(notificationData);
  
    const lastLogin = new Date(JSON.parse(window.localStorage.getItem("user")).fecha_ult_ingreso);
    const newPays = payments?.filter(
      (pay) => lastLogin <= new Date(pay.fecha_disponible)
    );
    if (newPays) setNewPayments(newPays);
  
    const filteredNotices = notices
      ?.filter(
        (notice) =>
          notice.destacado === "destacado" && checkPeriod(notice.periodo_vigencia)
      )
      .sort((a, b) => new Date(b.fecha_aviso) - new Date(a.fecha_aviso));
  
    if (filteredNotices) {
      setAllNotices(filteredNotices);

      const noticesToConfirm = notices?.filter((notice) => {
        if (
          !notice.conformidad || 
          notice.conformidad.trim() === '' || 
          notice.adjunto
        ) {
          return false;
        }
      
        const destinatarios = notice.destino?.split(',').map(d => String(d).trim()) || [];
        const isTargetProvider = destinatarios.includes("Todos los proveedores") || destinatarios.some(d => d === cuit);
      
        if (!isTargetProvider) {
          return false;
        }
      
        const providerConfirmation = allConformities?.some(
          (confirmation) =>
            confirmation.id_aviso === notice.id_aviso &&
            String(confirmation.cuit).trim() === cuit
        );
      
        const needsConfirmation = !providerConfirmation;
      
        const isCurrent = checkPeriod(notice.periodo_vigencia);
      
        return needsConfirmation && isCurrent;
      });

setNoticesRequiringConfirmation(noticesToConfirm);

if (noticesToConfirm.length > 0) {
  const firstNotice = noticesToConfirm[0];
  setCurrentNotice(firstNotice);

  if (firstNotice.id_documento_adjunto) {
    setLoadingDocument(true); 

    DocumentsNoticesContext.fetchDocumentsNoticesById(firstNotice.id_documento_adjunto)
      .then((file) => {
        setNoticeDocument(file?.data[0]); 
      })
      .finally(() => setLoadingDocument(false)); 
  } else {
    setNoticeDocument(null); 
  }

  setOpenModal(true);
} else {
  setCurrentNotice(null); 
}
setIsLoading(false);
}
  }, [id_proveedor, cuit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleConfirmity = (notice) => {
    const conformity = {
      mail: JSON.parse(localStorage.getItem("user")).mail,
      cuit: cuit,
      IdAviso: notice.id_aviso,
      confirmacion: true,  
    };
  
    ConformityProvidedContext.setConformityProvided(conformity)
      .then(() => {
        setOpenModal(false);
  
        const nextNotices = noticesRequiringConfirmation.filter(
          (item) => item.id_aviso !== notice.id_aviso
        );
        setNoticesRequiringConfirmation(nextNotices);
  
        if (nextNotices.length > 0) {
          const firstNotice = nextNotices[0];
          setNoticeDocument(null); 
          setLoadingDocument(false); 
          setCurrentNotice(firstNotice);
  
          if (firstNotice.id_documento_adjunto) {
            setLoadingDocument(true); 
            DocumentsNoticesContext.fetchDocumentsNoticesById(firstNotice.id_documento_adjunto)
              .then((file) => {
                setNoticeDocument(file?.data[0]); 
              })
              .finally(() => setLoadingDocument(false));
          } else {
            setNoticeDocument(null); 
          }
  
          setOpenModal(true);
        } else {
          setCurrentNotice(null); 
        }
      })
      .catch((error) => {
        console.error("Error al confirmar el aviso:", error);
      });
  };

  const formatString = (str) => {
    return str.length > 100 ? str.substring(0, 100) + "..." : str;
  };

  function getState(column) {
    if (allStates !== null && allStates !== undefined && allStates !== "") {
      let state = allStates.filter((state) => state.id_estado === column)[0]
        .descripcion_abreviada;
      return state;
    }
    return "No definido";
  }

  function checkPeriod(vigencia) {
    const check = new Date();
    const dates = vigencia.split("-");
    const from = new Date(dates[2], dates[1] - 1, dates[0]);
    const to = new Date(dates[5], dates[4] - 1, dates[3]);
    return check <= to && check >= from;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setHasContact(false);
  };

  return (
    <Box mt={4}>
    {newness?.length > 0 || allNotices?.length > 0 || newPayments?.length > 0 ? (
      <Typography variant="h6" component="h2">
        Novedades.
      </Typography>
    ) : (
      <Typography className={classes.textLine}>
        Sin novedades para este proveedor.
      </Typography>
    )}

    {isLoading && <Typography>Cargando novedades...</Typography>}

    <Box className={classes.scroller}>
  {allNotices && allNotices.length > 0 ? (  
    <>
      {allNotices.map((column, index) => (
        <Typography key={index} className={classes.textLine}>
          - El aviso <strong>{formatString(column.titulo_aviso)}</strong>{" "}
          se encuentra disponible.
        </Typography>
      ))}
    </>
  ) : null} 

  {newness && newness.length > 0 && ( 
    <>
      {newness.map((column) => (
        <Typography
          className={classes.textLine}
          key={column.id_documento}
        >
          - El documento{" "}
          <strong>
            {column.letra_documento +
              "-" +
              column.prefijo_documento.toString().padStart(5, "0") +
              "-" +
              column.numero_documento.toString().padStart(8, "0")}
          </strong>{" "}
          se encuentra en estado{" "}
          <strong>{getState(column.id_estado)}</strong> a partir del{" "}
          <strong>
            {moment(column.fecha_documento).format("DD/MM/YYYY")}
          </strong>
        </Typography>
      ))}
    </>
  )}
  
    {newPayments && newPayments.length > 0 && ( 
          <>
            {newPayments.map((column) => (
              <Typography className={classes.textLine} key={column.id_pago}>
                - El documento{" "}
                <strong>
                  {column.prefijo_pago.toString() +
                    "-" +
                    column.numero_pago.toString()}
                </strong>{" "}
                se encuentra en estado{" "}
                <strong>{getState(column.id_estado)}</strong> a partir del{" "}
                <strong>
                  {moment(column.fecha_disponible).format("DD/MM/YYYY")}
                </strong>
              </Typography>
            ))}
          </>
        )}
      </Box>


      <Box mt={4}>
  {isLoading && <Typography>Cargando novedades...</Typography>}
  <BasicModal open={openModal}>
  {currentNotice && (
    <>
      <Typography variant="h6" className={classes.modalTitle}>
        {currentNotice.titulo_aviso}
      </Typography>

      <Typography variant="body1" className={classes.modalBody}>
        {currentNotice.cuerpo_aviso}
      </Typography>


      {noticeDocument ? (
  <Box className={classes.modalAttachment}>
    {loadingDocument ? (
      <Box color={theme.palette.text.disabled} display="flex" alignItems="center" style={{ gap: 8 }}>
        Cargando adjunto
        <CircularProgress color="inherit" size={16} />
      </Box>
    ) : (
      <Link
        disabled={!noticeDocument}
        download={noticeDocument?.nombre_archivo}
        href={`data:${noticeDocument?.tipo_archivo};base64,${noticeDocument?.imagen}`}
      >
        Descargar adjunto
        <GetAppIcon className={classes.iconButton} />
      </Link>
    )}
  </Box>
) : null}

      <Button
        className={classes.modalButton}
        variant="contained"
        color="primary"
        onClick={() => {
          handleConfirmity(currentNotice);
          setOpenModal(false);
        }}
      >
        Confirmar
      </Button>
    </>
  )}
</BasicModal>

</Box>

      <Box mt={4}>
        <Typography variant="h6" component="h2">
          Tus datos.
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <Box mr={10}>
          <Typography className={classes.textLine}>
            <strong>Nombre de usuario: </strong>
            {user.props.razon_social}
          </Typography>
          <Typography className={classes.textLine}>
            <strong>CUIT asociado: </strong>
            {user.props.cuit}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Razón social: </strong>
            {user.props.razon_social}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Domicilio: </strong>
            {user.props.domicilio}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.textLine}>
            <strong> Localidad: </strong>
            {user.props.localidad}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Código Postal: </strong>
            {user.props.codigo_postal}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Teléfono: </strong>
            {user.props.telefono}
          </Typography>
        </Box>
      </Box>
      
      {hasContact && (
        <Snackbar open={hasContact} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            Tiene respuestas a sus consultas en Contactos
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
  
}