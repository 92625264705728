import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import StyledPagination from "../UI/StyledPagination";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import TableFilter from "../UI/TableFilter";
import LoadingIcon from "../UI/LoadingIcon";

const useStyles = makeStyles({
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  tableHeaderText: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCellText: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  tableRow: {
    cursor: "pointer",
  },
  lastColumn: {
    paddingLeft: "6em",
  },
  conformityYesIcon: {
    color: "green",
  },
  conformityNoIcon: {
    color: "red",
  },
});

const columns = [
  {
    id: "fecha_aviso",
    label: "",
    minWidth: 50,
    align: "left",
  },
  {
    id: "titulo_aviso",
    label: "",
    minWidth: 150,
    align: "left",
  },
  {
    id: "conformidad",
    label: "Conformidad brindada",
    minWidth: 150,
    align: "right",
  },
];

const TAB_STORAGE = "tab_notices";

const NoticesTable = (props) => {
  const {
    data = [],
    isLoading,
    rowsPerPage,
    filterOption,
    searchFilter,
    noFilter,
    fromDate,
    toDate,
  } = props;

  const [page, setPage] = useState(1);

  const classes = useStyles();

  const cuit = JSON.parse(window.localStorage.getItem("provider")).cuit;

  const hasConformity = (notice) => {
    return notice?.conformityProvided.length > 0 &&
      notice?.conformityProvided.some((el) => parseInt(el.cuit) === parseInt(cuit));
  }
  const mustShowCheck = (notice) =>
    Boolean(notice?.conformidad || notice?.adjunto);

  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const noticeOpenHandler = (noticeId, conformityColumn) => {
    if (!data) return;
    const notice = data.find((notice) => notice.id_aviso === noticeId);

    props.openNotice(
      notice,
      conformityColumn && mustShowCheck(notice) && !hasConformity(notice)
    );
  };

  const showConformityIcon = (notice) => {
    const conformity = hasConformity(notice);
    const showCheck = mustShowCheck(notice);
    if (showCheck) {
      if (!conformity) {
        return <CloseIcon className={classes.conformityNoIcon} />;
      } else {
        return <CheckIcon className={classes.conformityYesIcon} />;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    setPage(1);
  }, [props.searchFilter, props.filterOption, props.fromDate, props.toDate]);

  const hasData = data && data.length > 0;
  const firstIndexInPage = (page - 1) * rowsPerPage;

  // Procesar datos para mostrar por búsqueda, filtro y paginación
  const statusFilteredData =
    filterOption !== noFilter
      ? data.filter((d) => {
        const filterString = filterOption.toString();
        return (
          mustShowCheck(d) && hasConformity(d).toString() === filterString
        );
      })
      : data;

  const dateFilteredData = statusFilteredData.filter((notice) => {
    return (
      (toDate ? notice.vigencia_comienzo <= toDate : true) &&
      (fromDate ? notice.vigencia_final >= fromDate : true)
    );
  });

  const searchFilteredData =
    searchFilter === ""
      ? dateFilteredData
      : dateFilteredData.filter((d) => d.titulo_aviso.includes(searchFilter));

  const pageCount = Math.ceil(searchFilteredData.length / rowsPerPage);
  const paginatedData = searchFilteredData.slice(
    firstIndexInPage,
    firstIndexInPage + rowsPerPage
  );

  return (
    <div>
      <Typography variant="h5">{props.tableTitle}</Typography>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={classes.tableHeaderText}
                  style={{ width: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {hasData &&
              paginatedData.map((row) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  role="checkbox"
                  key={row.id_aviso}
                >
                  <TableCell className={classes.tableCellText}>
                    {row.periodo_vigencia}
                  </TableCell>
                  <TableCell
                    className={classes.tableCellText}
                    onClick={() => noticeOpenHandler(row.id_aviso, false)}
                  >
                    {row.titulo_aviso}
                  </TableCell>
                  <TableCell
                    className={classes.lastColumn}
                    onClick={() => noticeOpenHandler(row.id_aviso, true)}
                  >
                    {showConformityIcon(row)}
                  </TableCell>
                </TableRow>
              ))}
            {!hasData && !isLoading && (
              <TableRow hover role="checkbox">
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    No hay avisos para mostrar.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {hasData && (
        <StyledPagination
          page={page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}
    </div>
  );
};

const MyNotices = (props) => {
  const filterOptions = [
    { value: "all", label: "Todos" },
    { value: "false", label: "Sin brindar" },
    { value: "true", label: "Brindada" },
  ];
  const { data = [], rowsPerPage, isLoading, tabIndex } = props;

  const [searchFilter, setSearchFilter] = useState("");
  const [filterOption, setFilterOption] = useState(filterOptions[0].value);
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const classes = useStyles();

  const openNoticeHandler = (notice, showAccordance) => {
    sessionStorage.setItem(TAB_STORAGE, tabIndex);
    if (showAccordance) {
      props.openNotice(notice);
    } else {
      props.openCommonNotice(notice);
    }
  };
  const searchFilterHandler = (newValue) => {
    setSearchFilter(newValue);
  };
  const statusFilterHandler = (newFilter) => {
    setFilterOption(newFilter.status);
    setStartDateFilter(newFilter.startDate);
    setEndDateFilter(newFilter.endDate);
  };

  const markedNotices = data
    ? data.filter((notice) => notice.destacado.toLowerCase() === "destacado")
    : [];
  const unmarkedNotices = data
    ? data.filter((notice) => notice.destacado.toLowerCase() !== "destacado")
    : [];

  return (
    <div>
      <Box mt={1} mb={2} className={classes.actionBar}>
        <TableFilter
          className={classes.actionSearch}
          options={filterOptions}
          optionLabel="Conformidad"
          selectedFilter={filterOption}
          currentSearch={searchFilter}
          showDatePicker
          searchPlaceholder="Buscar por título de aviso"
          onFilterChange={statusFilterHandler}
          onSearchChange={searchFilterHandler}
        />
      </Box>

      <NoticesTable
        tableTitle={"Destacados"}
        data={markedNotices}
        rowsPerPage={rowsPerPage}
        openNotice={openNoticeHandler}
        filterOption={filterOption}
        noFilter={filterOptions[0].value}
        searchFilter={searchFilter}
        fromDate={startDateFilter}
        toDate={endDateFilter}
        isLoading={isLoading}
      />
      <NoticesTable
        tableTitle={"Sin destacar"}
        data={unmarkedNotices}
        rowsPerPage={rowsPerPage}
        openNotice={openNoticeHandler}
        filterOption={filterOption}
        noFilter={filterOptions[0].value}
        searchFilter={searchFilter}
        fromDate={startDateFilter}
        toDate={endDateFilter}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MyNotices;
