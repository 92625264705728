import React from "react";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  box: {
    background: "white",
    border: 0,
    borderRadius: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1300,
  }
});

const BasicModal = (props) => {
  const classes = useStyles();

  return (
    <Modal
      open={props.open}
      onClose={null}  
      disableBackdropClick={true} 
      disableEscapeKeyDown={true} 
    >
      <Box className={`${classes.box} ${props.className}`} style={props.style}>
        {props.children}
      </Box>
    </Modal>
  );
};

export default BasicModal;
